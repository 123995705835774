/*
 * utility:Log
 *
 * Logging class providing different log modes for logging messages.
 * @scriptName    string    name of the script the Log-Object is created for
 */

// contains names of scripts to debug or "all" for debug all.
import {useConfig} from "@/services/config.service";

const getDebuggingMode = (): string[] => {
    if (useConfig().getDebug()) {
        if (useConfig().getDebug() === true) {
            return ["all"];
        } else if (typeof useConfig().getDebug() === "string") {
            return [useConfig().getDebug() as string];
        } else {
            return useConfig().getDebug() as string[];
        }
    } else {
        return []
    }
}

export class Log {
    private readonly scriptName:string;
    private readonly debugFlag: boolean;

    constructor(scriptName:(string | undefined)) {
        const debuggingMode = getDebuggingMode();
        this.scriptName = scriptName ? scriptName : "unknown";
        this.debugFlag = debuggingMode.includes(this.scriptName) || debuggingMode.includes("all");
    }

    public info(msg:string) {
        console.log('INFO(' + this.scriptName + ') ' + msg);
    }

    public warn(msg:string) {
        console.warn('WARN(' + this.scriptName + ') ' + msg);
    }

    public error(msg:string) {
        console.error('ERROR(' + this.scriptName + ') ' + msg);
    }

    public debug(msg:string) {
        if (this.debugFlag) console.debug('DEBUG(' + this.scriptName + ') ' + msg);
    }

    public willDebug() {
        return this.debugFlag;
    }
}
