import {_COLORS} from "@/assets/colors";

export class StringUtils {
    /**
     * Adds leading zeros to a number up to a certain length and returns it as a string. E.g. padz(4,3) would return "004".
     *
     * @param number The number to be padded
     * @param size The length to which the number should be extended with zeros
     * @returns String containing the number padded with zeros
     */
    public static padz(number: number, size: number): string {
        let num = number.toString();
        while(num.length < size) {
            num = "0" + num;
        }
        return num;
    }

    /**
     * Checks if the string is an url.
     *
     * @param string The string to be checked
     * @returns true if string is a valid url, otherwise false
     */
    public static isURL(string: any): boolean {
        try {
            new URL(string);
            return true;
        } catch (_) {
            return false;
        }
    }

    /**
     * Evaluates a string an executes java script from the string.
     *
     * @param exp is a js expression (e.g. "(true == true) ? 1 : 2")
     * @returns the value returned by the evaluated expression
     */
    public static evaluateExp(exp: string) {
        return eval(exp.replace(/\bAND\b/g, '&&').replace(/\bOR\b/g, '||'));
    }

    /**
     * Creates a duration string in a human-readable format (e.g. "1h 20min").
     *
     * @param duration is a numeric duration in milliseconds
     * @returns a string containing the duration information
     */
    public static fromDuration(duration: number) {
        const durationHours = Math.floor(duration/(60*60*1000));
        const durationMinutes = Math.floor((duration - durationHours * (60*60*1000))/(60*1000));
        return durationHours > 0 ? `${durationHours}h ${durationMinutes}min` : `${durationMinutes < 1 ? '<1' : durationMinutes}min`
    }

    /**
     * Converts a colorname to a hex color.
     *
     * @param name is the name of the color, e.g. ("black", "green", "blue"...)
     */
    public static colorNameToHex(name: string): string {
        if (Object.keys(_COLORS).includes(name.toLowerCase())) {
            return (_COLORS as any)[name.toLowerCase()];
        } else {
            if (name.charAt(0) === "#") {
                return name;
            }
            return _COLORS.black;
        }
    }

    /**
     * Checks if a potential string is a valid hex color.
     *
     * @param str - The potential string to check.
     * @returns True if the string is a valid hex color, otherwise false.
     */
    public static isHexColor(str: string | undefined | null): boolean {
        if (str === undefined || str === null) {
            return false;
        }
        // Regular expression to match valid hex color codes
        const hexColorRegex = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3}|[A-Fa-f0-9]{8})$/;
        return hexColorRegex.test(str);
    }

    public static buildZmRef(path: string) {
        return `{{ZM_REF[${path}]}}`
    }

    public static extractZmRef(zmRef: string) {
        return zmRef.split("{{ZM_REF[")[1].split("]}}")[0];
    }
}
