import {i_mapSettings} from "@/types/interfaces/frontend.interfaces";
import {isMapSettings} from "@/types/typeguards/frontend.guards";
import {Log} from "@/utils/log";
import axios from "axios";

const STD_MAP_SETTINGS = {
    title: "Z-Move",
    startPosition: [50.706, 12.459] as [number, number],
    startZoom: 13,
    storeLastPosition: false,
    debug: false
}

class ConfigService {
    private config: i_mapSettings;
    constructor() {
        this.config = STD_MAP_SETTINGS as i_mapSettings;
    }

    public async init() {
        const configFileResponse = await axios.get("/config.json");
        this.config = STD_MAP_SETTINGS as i_mapSettings;
        if (isMapSettings(configFileResponse.data)) {
            for (const [key, value] of Object.entries(configFileResponse.data)) {
                if (value) {
                    this.config[key as keyof typeof configFileResponse.data] = value;
                }
            }
        } else {
            console.error("Invalid config.json was supplied!");
        }
        if (this.getDebug()) {
            console.log(`Debugging-Mode: ${this.getDebug()}`);
        }
        return this.config;
    }

    public getLastPosition(): boolean {
        return this.config.storeLastPosition || STD_MAP_SETTINGS.storeLastPosition;
    }

    public getStartZoom(): number {
        return this.config.startZoom || STD_MAP_SETTINGS.startZoom;
    }

    public getStartPosition(): [number, number] {
        return this.config.startPosition || STD_MAP_SETTINGS.startPosition;
    }

    public getTitle(): string {
        return this.config.title || STD_MAP_SETTINGS.title;
    }

    public getDebug(): boolean | string | string[] {
        return this.config.debug || STD_MAP_SETTINGS.debug;
    }

    public getKeycloak() {
        return this.config.keycloak;
    }
}

const configService = new ConfigService();

export const useConfig = () => {
  return configService;
}
