
class WatchService {
    private date;

    constructor() {
        this.date = new Date();
    }

    public getHours() {
        this.date = new Date();
        return this.date.getHours();
    }

    public getMinutes() {
        this.date = new Date();
        return this.date.getMinutes();
    }

    public getSeconds() {
        this.date = new Date();
        return this.date.getSeconds();
    }

    public getYear() {
        this.date = new Date();
        return this.date.getFullYear();
    }

    public getMonth() {
        this.date = new Date();
        return this.date.getUTCMonth() + 1;
    }

    public getDay() {
        this.date = new Date();
        return this.date.getDate();
    }

    public getWeekday() {
        this.date = new Date();
        return this.date.getDay() + 1;
    }
}

const watchService = new WatchService();

export const useWatch = (): WatchService => {
    return watchService;
}
